



































































































import { computed, defineComponent, PropType, Ref, ref } from '@vue/composition-api';
import { useForm, Form } from '@/admin/form';
import { useNotification } from '@/composition/notification';
import { DisplayDate } from '@/admin/util';
import { useConfirm } from '@/composition/confirm';
import FcDate from '@/components/FcDate.vue';
import FcCollection from '@/components/FcCollection.vue';
import FcAuthority from '@/components/FcAuthority.vue';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'Form',
  components: {
    FcDate,
    FcCollection,
    FcAuthority,
    FcImage,
    FcStaticImage,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    formId: {
      type: String,
      default: '',
    },
    copyForm: {
      type: Object as PropType<Form | null>,
      default: null,
    },
  },
  setup({ formId, copyForm }, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('forms'));

    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const { getForm, saveForm } = useForm();
    const form: Ref<Form | null> = ref(null);

    if (copyForm) {
      form.value = copyForm;
      delete form.value.formId;
      form.value.formName = '【アイテム複製】' + form.value.formName;
      form.value.isAllowUpdateProperties = true;
      form.value.entryStartDate = new DisplayDate();
      form.value.entryEndDate = new DisplayDate();
    } else {
      getForm(formId)
        .then((result) => {
          form.value = result;
        })
        .catch((error) => {
          console.error(error);
        });
    }

    const isSaving = ref(false);
    const save = async () => {
      if (!form.value) {
        alert('no form');
        return;
      }
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        await saveForm(form.value);
        notification.notify('保存しました');
        if (!formId) context.root.$router.push('/forms');
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    return {
      pageTitle: 'フォーム',
      myRoleSettings,
      form,
      isSaving,
      save,
    };
  },
});
